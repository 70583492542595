<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add stop custom action"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddCustomAction"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div v-if="filteredCustomActions && filteredCustomActions.length">
            <md-table class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head>Event Trigger</md-table-head>
                    <md-table-head>Recipient(s)</md-table-head>
                    <md-table-head>Template</md-table-head>
                    <md-table-head class="end">Actions</md-table-head>
                </md-table-row>
                <md-table-row v-for="item in filteredCustomActions" :key="item.teamCustomActionId">
                    <md-table-cell>
                        {{
                            ($_isEmptyOrSpaces(item.eventTriggerName) ? item.eventTrigger : item.eventTriggerName) +
                                toFilterString(item)
                        }}
                    </md-table-cell>
                    <md-table-cell>{{ toEmailRecipientTypesText(item.settings.emailRecipientTypes) }}</md-table-cell>
                    <md-table-cell>
                        {{ item.settings.useSystemTemplate ? 'Default' : item.settings.emailTemplateId }}
                    </md-table-cell>
                    <md-table-cell align="end" class="action-buttons">
                        <!-- Custom actions that doesn't have any eventTriggerName means they were manually added in the database
                            and is not publicly available as an eventTrigger. So we temporarily remove the ability to edit as to not cause any issues. -->
                        <md-button
                            v-if="hasCustomTemplate(item) && !$_isEmptyOrSpaces(item.eventTriggerName)"
                            title="Update custom template"
                            class="md-info md-just-icon md-round"
                            @click.stop="handleUpdateTemplate(item)"
                            :data-test-id="`btn-update-template-${item.teamCustomActionId}`"
                        >
                            <md-icon>subject</md-icon>
                        </md-button>
                        <md-button
                            v-if="!$_isEmptyOrSpaces(item.eventTriggerName)"
                            title="Update custom action"
                            class="md-warning md-just-icon md-round"
                            @click.stop="handleUpdate(item.teamCustomActionId)"
                            :data-test-id="`btn-update-${item.teamCustomActionId}`"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            title="Delete custom action"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDelete(item.teamCustomActionId)"
                            :data-test-id="`btn-delete-${item.teamCustomActionId}`"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <div v-else>
            <p class="no-result-message">No actions found.</p>
        </div>
    </div>
</template>

<script>
import { showErrorMessage } from '@/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions, mapGetters } from 'vuex';
import { TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS } from '@/utils/constants';
import { TEAM_CUSTOM_ACTION_DEFAULTS } from '@/utils/defaults';
import CreateEmailAction from './CreateEmailAction';
import EmailTemplateModal from './EmailTemplateModal';

export default {
    mixins: [GeneralMixin],
    props: {
        eventTrigger: { type: String, default: null }
    },
    data() {
        return {
            action: 'Email',
            selectedCustomAction: null,
            emailRecipientOptions: TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS
        };
    },
    computed: {
        ...mapGetters({ customActions: 'custom-actions/emailCustomActions' }),
        filteredCustomActions() {
            if (!this.eventTrigger) 
                return this.customActions;

            return this.customActions.filter((x) => x.eventTrigger === this.eventTrigger);
        }
    },
    methods: {
        ...mapActions('custom-actions', ['FETCH_EVENT_TRIGGERS', 'DELETE_EVENT_TRIGGER']),
        handleAddCustomAction() {
            const customAction = { ...TEAM_CUSTOM_ACTION_DEFAULTS() };
            customAction.action = this.action;
            this.$modal
                .show(CreateEmailAction, {
                    customActionModel: customAction
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        handleUpdateTemplate(customAction) {
            this.$modal.show(EmailTemplateModal, { customAction }).then((resp) => {
                //
            });
        },
        handleUpdate(teamCustomActionId) {
            const customAction = this.customActions.find((x) => x.teamCustomActionId === teamCustomActionId);

            if (!customAction) 
                return;

            const customActionModel = cloneDeep(customAction);
            this.$modal
                .show(CreateEmailAction, {
                    customActionModel,
                    isUpdate: true
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async handleDelete(teamCustomActionId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Event Trigger',
                    body: 'Are you sure you want to delete this event trigger?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteEventTrigger(teamCustomActionId);
                    }
                });
        },
        async deleteEventTrigger(teamCustomActionId) {
            this.$_handleLoaderState(true, 'DELETING EVENT TRIGGER...');

            await this.DELETE_EVENT_TRIGGER({ actionType: this.action, teamCustomActionId })
                .then(() => {
                    this.$notify({
                        message: 'Event trigger deleted.',
                        type: 'success'
                    });
                })
                .catch((e) => {
                    const message = 'Could not remove event trigger.';
                    showErrorMessage(this, message, e);
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        toFilterString(item) {
            if (item.filter && item.filter.status && item.filter.status.length) {
                return ` (${item.filter.status.join(', ')})`;
            }
            return '';
        },
        toEmailRecipientTypesText(recipientTypes) {
            if (this.emailRecipientOptions && recipientTypes) {
                const texts = [];
                for (let i = 0; i < recipientTypes.length; i++) {
                    const r = this.emailRecipientOptions.find((x) => x.value === recipientTypes[i]);
                    if (r) 
                        texts.push(r.text);
                }
                return texts.join(', ');
            }
            return '-';
        },
        hasCustomTemplate(customAction) {
            return customAction.settings.emailTemplateId && customAction.settings.emailTemplateId.length;
        }
    },
    mounted() {
        this.FETCH_EVENT_TRIGGERS({ actionType: this.action });
    }
};
</script>

<style lang="scss" scoped>
.md-table-head.end {
    text-align: right;
}
::v-deep .md-table-cell.action-buttons {
    padding-right: 30px;
    text-align: right;
}
</style>
