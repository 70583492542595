var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Custom Email Template"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _c(
              "form-group",
              {
                staticClass: "event-trigger no-underline",
                attrs: { label: "Event Trigger" },
              },
              [
                _c("md-input", {
                  attrs: {
                    id: "custom-template-eventTriggerName-input",
                    value: _vm.eventTriggerNameStatus,
                    readonly: "",
                  },
                }),
              ],
              1
            ),
            _c(
              "form-group",
              { staticClass: "custom-merge-field", attrs: { label: "Fields" } },
              [
                _c(
                  "drop-down",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "dropdown-toggle md-info",
                        attrs: { slot: "title", "data-toggle": "dropdown" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                        slot: "title",
                      },
                      [
                        _vm._v(
                          "\n                        Insert Merge Field\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "dropdown-menu dropdown-menu-right dropdown-menu-height",
                      },
                      _vm._l(_vm.mergeFields, function (field) {
                        return _c("li", { key: field.key }, [
                          field.key === "stopcustomfields" ||
                          field.key === "shipmentcustomfields"
                            ? _c(
                                "span",
                                { staticClass: "custom-field-header" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(field.name) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.insertMergeField(field.key)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(field.name) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { staticClass: "custom-textarea", attrs: { name: "template" } },
              [
                _c("md-textarea", {
                  ref: "template",
                  model: {
                    value: _vm.form.template,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "template", $$v)
                    },
                    expression: "form.template",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.updateTemplate },
          },
          [_vm._v("Save")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }